// App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";
import "./App.css";
import { LandingPage } from "./pages/LandingPage";

import { HelmetProvider } from "react-helmet-async";

const DEBUG = false;
function App() {
  return (
    <StyledApp className="App">
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </HelmetProvider>
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
