import { runLocal, testBook } from "./debug";

const baseUrl = runLocal
  ? "http://127.0.0.1:5000/"
  : "https://main-backend-api-332ea47a673b.herokuapp.com/";

export const saveEmail = async (email) => {
  try {
    console.log("Attempting to save email:", email);
    const url = `${baseUrl}/wpft/api/save_email`;
    console.log("Request URL:", url);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    console.log("Response status:", response.status);
    if (!response.ok) {
      console.error("Failed to save email. Status:", response.status);
    } else {
      console.log("Email saved successfully.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
